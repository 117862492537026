<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="uc_con">

      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>提现申请信息</div>
      </div>
      <div class="d_f ali_b">

        <el-form  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm">
          <el-form-item label="提现金额" prop="amount">
            <el-input v-model.number="ruleForm.amount" :max="balance"></el-input>
          </el-form-item>
          <div class="top_red"> 实际提现金额<span class="red_text">{{ruleForm.amount - percent*ruleForm.amount}}</span><span class="mr">元</span> 手续费<span class="red_text">{{percent*ruleForm.amount}}</span>元</div>

        </el-form>
        <div class="flex_1 d_f ali_c ri_mon">
          <span class="flex_1 t_r">可用余额(元)</span>
          <span class="mon">{{balance}}</span>
        </div>
      </div>
    </div>

    <div class="uc_con">
      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>到账账户信息</div>
        <div class="flex_1 d_f ali_b ch_tip">
          <img src="../assets/images/ch_tip.png" alt="">
          <span>提示<span class="red_text">*</span>：余额提现只能转入对公账户，若需更改对公账户信息，请拨打客服电话申请</span>
        </div>
      </div>
      <el-form  class="d_f ali_c j_s">

        <el-form-item class="flex_1" label="收款单位名称：">
          <el-input disabled v-model="basicInfo.bankName"></el-input>
        </el-form-item>
        <el-form-item class="flex_1 ch_center" label="开户行：">
          <el-input disabled v-model="basicInfo.openingBank"></el-input>
        </el-form-item>
        <el-form-item class="flex_1" label="账号：">
          <el-input disabled v-model="basicInfo.bankNo"></el-input>
        </el-form-item>
      </el-form >
      <div class="ch_btn t_a">
        <div class="d_i cur_p" @click="apply">确定</div>
        <div class="d_i cur_p ac" @click="$router.go(-1)">取消</div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "cashing",
    data() {
      return {
        ruleForm:{
          amount:'',
        },
        rules:{
          amount:[
            { required: true, message: ' ', trigger: 'blur' },
          ]
        },
        balance:0,
        percent:0,
        basicInfo:{},
      }
    },
    created(){

      this.getBusinessWallet();
      this.getWithdrawWerviceRatio();
      this.getBusinessInfo();
    },
    methods: {
      apply(){
        if(!this.ruleForm.amount) return this.$message.error('请输入提现金额');
        if(this.ruleForm.amount > this.balance) return this.$message.error('提现金额不能大于可用余额');
        this.$api.apply({amount:this.ruleForm.amount}).then(res => {
          this.$message.success('操作成功');
          this.$refs['ruleForm'].resetFields();
          this.ruleForm.amount = '';
          this.getBusinessWallet();
        })
      },
      getBusinessInfo(){
        this.$api.getBusinessInfo().then(res => {
          this.basicInfo = res.data;
        })
      },
      // 提现手续比例
      getWithdrawWerviceRatio(){

        this.$api.getWithdrawWerviceRatio().then(res => {
          this.percent = res.data ? Number(res.data/100).toFixed(2) : 0;
        })
      },
      //查询账户余额
      getBusinessWallet(){
        this.$api.getBusinessWallet().then(res => {
          let freezeAmount = res.data.freezeAmount ? Number(res.data.freezeAmount/100).toFixed(2) : 0;
          let balance = res.data.balance ? Number(res.data.balance/100).toFixed(2) : 0;
          this.balance = balance - freezeAmount;
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  .ch_btn{
    div{
      &.ac{
        margin-left: 18px;
      }
    }
    margin-top: 160px;
    margin-bottom: 230px;
  }
  .ch_center{
    margin: 0 173px;
  }
  .ch_tip{
    img{
      width: 28px;
      height: 28px;
      margin-right: 17px;
    }
    margin-left: 39px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  .ri_mon{
    .mon{
      font-size: 56px;
      font-weight: bold;
      color: #000000;
      margin: 0 80px 0 34px;
    }
    font-size: 22px;
    font-weight: 400;
    color: #333333;

  }
  :v-deep .el-form-item{
    margin-bottom: 10px;
  }
  :v-deep .el-form-item__label{
    font-size: 20px;
  }
  .top_red{
    font-size: 20px;
    font-weight: 400;
    color: #A4A4A4;
    padding: 0 200px 0 120px;
    .red_text{
      color: #FF0000;
    }
    .mr{
      margin-right: 50px;

    }
  }
</style>
